import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [myOrgs, setMyOrgs] = useState([]);

  const org_admin = "Organization Administrator";

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  useEffect(() => {
    // console.log(user)
    if (isAuthenticated && "_org_roles" in user) {
      if ("_member_of_orgs" in user) {
        setMyOrgs(user._member_of_orgs);
      }
      setIsOrgAdmin(user._org_roles.includes(org_admin));
    }
  }, []);

  return (
    <>
      <style>
        {`
        .nav-container {
          // border: 1px red solid;
        }
        .login-buttons {
          // border: 1px green solid;
          display: flex !important;
          flex-direction: row !important;
          // width: 35%;
          justify-content: space-around;
        }
        .login-buttons > * {
          margin: 0 10px 0 10px;
        }

        img {
          width: 50px;
        }

        .linkcolor { 
          color: #3a6d2c;
        }

        .navbar-toggler-icon {
          background: #3a6d2c !important;
          height: 3px;
        }
        .navbar-toggler-icon:hover {
          background: yellow !important;
        }
      
      `}
      </style>
      <div className="nav-container">
        <Navbar color="light" expand="md" container={false}>
          <Container>
            <NavbarBrand className="logo">
              <img src="https://imgs.search.brave.com/zv0Es_hrAon1fbkKxIMYV9-59ksL1m0ocJSFXFikavY/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly93d3cu/cGluY2xpcGFydC5j/b20vcGljZGlyL2Jp/Zy8xMDMtMTAzNTA0/NF9iYXRhdmlhLWxh/bmRzY2FwaW5nLXBh/dmluZy1hbmQtcGF0/aW9zLWxlYWYtY2ly/Y2xlLWxvZ28ucG5n" />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/"
                    exact
                    activeClassName="router-link-exact-active"
                    className="linkcolor"
                  >
                    Home
                  </NavLink>
                </NavItem>
                {isAuthenticated && (
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/permissions"
                      exact
                      activeClassName="router-link-exact-active"
                      className="linkcolor"
                    >
                      Permissions
                    </NavLink>
                  </NavItem>
                )}

                {isAuthenticated && (
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/apps"
                      exact
                      activeClassName="router-link-exact-active"
                      className="linkcolor"
                    >
                      Applications
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <Nav>
                {isAuthenticated && myOrgs.length > 0 && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      caret
                      id="profileDropDown"
                      className="linkcolor"
                    >
                      <b>{user && user._org_display_name}</b>
                    </DropdownToggle>
                    <DropdownMenu>
                      {myOrgs.map((org) => {
                        if (org.id !== user.org_id)
                          return (
                            <DropdownItem
                              onClick={() => {
                                localStorage.clear();
                                loginWithRedirect({
                                  authorizationParams: { organization: org.id },
                                });
                              }}
                              className="linkcolor"
                              key={`${org.id}`}
                            >
                              {org.display_name}
                            </DropdownItem>
                          );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </Nav>
              <Nav className="d-none d-md-block login-buttons" navbar>
                {!isAuthenticated && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      caret
                      id="profileDropDown"
                      className="linkcolor"
                    >
                      Companies
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          loginWithRedirect({
                            authorizationParams: {
                              "ext-origin": window.location.origin,
                            },
                          });
                        }}
                        className="linkcolor"
                      >
                        Log In
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          loginWithRedirect({
                            authorizationParams: {
                              organization: "atko",
                              "ext-origin": window.location.origin,
                            },
                          });
                        }}
                        className="linkcolor"
                      >
                        Log into Atko
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          loginWithRedirect({
                            authorizationParams: {
                              organization: "acme",
                              "ext-origin": window.location.origin,
                            },
                          });
                        }}
                        className="linkcolor"
                      >
                        Log into Acme
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {!isAuthenticated && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      caret
                      id="profileDropDown"
                      className="linkcolor"
                    >
                      Customers
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          window.open("https://atko-mv.vercel.app", "_blank");
                        }}
                        className="linkcolor"
                      >
                        Portal
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}

                {isAuthenticated && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret id="profileDropDown">
                      <img
                        src={user.picture}
                        alt="Profile"
                        className="nav-user-profile rounded-circle"
                        width="50"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>{user.name}</DropdownItem>
                      <DropdownItem
                        tag={RouterNavLink}
                        to="/profile"
                        className="dropdown-profile"
                        activeClassName="router-link-exact-active"
                      >
                        <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                      </DropdownItem>
                      {isAuthenticated && isOrgAdmin && (
                        <DropdownItem
                          id="qsOrgMgmtBtn"
                          tag={RouterNavLink}
                          to="/org"
                          className="dropdown-profile"
                          activeClassName="router-link-exact-active"
                        >
                          <FontAwesomeIcon icon="square" className="mr-3" /> Org
                          Management
                        </DropdownItem>
                      )}
                      <DropdownItem
                        id="qsLogoutBtn"
                        onClick={() => {
                          logoutWithRedirect();
                          localStorage.clear();
                        }}
                      >
                        <FontAwesomeIcon icon="power-off" className="mr-3" />{" "}
                        Log out
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </Nav>
              {/* {isAuthenticated && (
                <Nav
                  className="d-md-none justify-content-between"
                  navbar
                  style={{ minHeight: 170 }}
                >
                  <NavItem>
                    <span className="user-info">
                      <img
                        src={user.picture}
                        alt="Profile"
                        className="nav-user-profile d-inline-block rounded-circle mr-3"
                        width="50"
                      />
                      <h6 className="d-inline-block">{user.name}</h6>
                    </span>
                  </NavItem>
                  <NavItem>
                    <FontAwesomeIcon icon="user" className="mr-3" />
                    <RouterNavLink
                      to="/profile"
                      activeClassName="router-link-exact-active"
                    >
                      Profile
                    </RouterNavLink>
                  </NavItem>
                  <NavItem>
                    <FontAwesomeIcon icon="power-off" className="mr-3" />
                    <RouterNavLink
                      to="#"
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      Log outs
                    </RouterNavLink>
                  </NavItem>
                </Nav>
              )} */}
            </Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default NavBar;
